import { inject } from '@angular/core';
import type { PermissionEnum } from '@pi/pi-common/src/ui-dtos/permission.dtos';

import { PermissionService } from '../../lib/services/permission/permission.service';

export type PermissionComparisonType = 'EVERY' | 'SOME';

export async function hasPermissionsFn(
    permissionService: PermissionService,
    permissions: PermissionEnum[] | PermissionEnum,
    comparison: PermissionComparisonType = 'EVERY'
): Promise<boolean> {
    // Support both a single permission as well as an array
    let permissionArray: PermissionEnum[] = [];
    if (Array.isArray(permissions)) {
        permissionArray = permissions;
    } else {
        permissionArray = [permissions];
    }

    let result = false;
    if (comparison === 'EVERY') {
        result = (await permissionService.hasPermissions(...permissionArray)).every((permission) => permission);
    } else {
        result = await permissionService.hasSomePermission(permissionArray, 'ANY');
    }

    return result;
}

export const hasPermissionsResolver =
    (permissions: PermissionEnum[] | PermissionEnum, comparison: PermissionComparisonType = 'EVERY') =>
    () => {
        const permissionService = inject(PermissionService);
        return hasPermissionsFn(permissionService, permissions, comparison);
    };
